import { createSlice } from "@reduxjs/toolkit";

const userPermissions = createSlice({
  name: "user permissions",
  initialState: {
    userRole: null,
    permissions : {
        admin: {
            order: {
                create: true,
                option: true
            },
            diagnose: {
                create: true,
                update: true
            }
        },
        doctor: {
            order: {
                create: true,
                option: true
            },
            diagnose: {
                create: true,
                update: false
            }
        },
        nurse: {
            order: {
                create: false,
                option: false
            },
            diagnose: {
                create: false,
                update: false
            }
        }
    }
  },
  reducers: {
    setUserRole: (state, action) => {
      state.userRole = action.payload;
    },
    setPermissions: (state, action) => {
      state.permissions = action.payload;
    },
  },
});


export const { setUserRole, setPermissions } = userPermissions.actions;
export default userPermissions.reducer

