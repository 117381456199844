import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { menus, settings } from "./sideNav";
import { UserName } from "./profileLogo";
import { useDispatch, useSelector } from "react-redux";
import { IoChevronDownOutline, IoSearchOutline } from "react-icons/io5";
import { IoMdClose } from "react-icons/io";
import { MdNotifications } from "react-icons/md";
import { RxHamburgerMenu } from "react-icons/rx";
import { profieCardHandler, sideNavHandler } from "../redux/slices/handler";

const Header = ({className}) => {
  const [headerTitle, setHeaderTitle] = useState("dashboard");
  const location = useLocation();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state?.userData);
  const isOpenSideNav = useSelector((state) => state.handler.isOpenSideNav);
  // console.log(isOpenSideNav);

  useEffect(() => {
    if (location.state !== "") {
      const item1 = menus.filter(
        (item) => item.title === location.state?.headerTitle
      );
      const item2 = settings.filter(
        (item) => item.title === location.state?.headerTitle
      );
      if (item1) {
        setHeaderTitle(item1);
      } else {
        setHeaderTitle(item2);
      }
    }
  }, [location.state]);

  return (
    <div className={`flex justify-between items-center w-full bg-zinc-50 lg:bg-transparent ${className}`}>
      <div className=" py-2 px-3 w-1/2 flex  items-center justify-between lg:hidden">
        <button
          onClick={() => dispatch(sideNavHandler())}
          className="text-lg lg:text-2xl"
        >
          {!isOpenSideNav ? <RxHamburgerMenu /> : <IoMdClose />}
        </button>
      </div>
      <div className="flex items-center justify-end lg:justify-between px-5 w-full lg:border-b py-3">
        <div className="  hidden  lg:flex justify-between px-5 lg:px-10 items-center  ">
          <h1 className=" text-lg lg:text-2xl capitalize flex gap-2 items-center">
            <span className="lg:text-3xl">{headerTitle[0]?.icon} </span>
            {headerTitle[0]?.title}
          </h1>
        </div>
        <div className="lg:w-1/2">
          <div className="flex gap-2 lg:gap-5 items-center justify-end w-full ">
            <div className="flex items-center gap-5">
              {/* Search */}
              <div className="hidden  bg-zinc-50 border border-green-800 lg:flex  items-center py-1 px-1.5 rounded-lg w-full ">
                <div className="flex items-center gap-3">
                  <IoSearchOutline className="text-lg text-zinc-600" />
                  <input
                    placeholder="Search"
                    className="bg-transparent outline-none"
                  />
                </div>
              </div>

              {/* notification */}
              <button className="text-base lg:text-xl relative">
                <span className="p-1 bg-red-500 absolute right-0.5 top-0 rounded-full"></span>
                <MdNotifications />
              </button>
            </div>

            {/* user profile */}
            <NavLink className="flex items-center gap-1" onClick={()=> dispatch(profieCardHandler())} >
              <UserName />
              <IoChevronDownOutline />
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
