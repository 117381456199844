import React, { useEffect, useState } from "react";
import Layout from "../components/layout";
import SkeletonContainer from "../routes/skeleton";
import axios from "axios";
import { apiurl } from "../config/config.js";
import { RiDeleteBinLine } from "react-icons/ri";
import ReactPaginate from "react-paginate";

const Sessions = () => {
  const [sesstionData, setSessionData] = useState([]);
  const [DelelteId, setDelelteId] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 20; 
  const [filteredData, setFilteredData] = useState(null); 
  const [wantDelete, setWantDelete] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiurl}/api/v1/session/getall`);
        if (response.data.success) {
          setSessionData(response.data.data);
          setFilteredData(response.data.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const DeleteHandler = async (itemID) => {
    try {
      const updatedSessionData = sesstionData.filter(item => item?.sessionId !== itemID);
      setSessionData(updatedSessionData);
      setFilteredData(updatedSessionData); 

      const data = { sessionId: itemID };
      await axios.delete(`${apiurl}/api/v1/session/delete`, { data });

      setWantDelete(false);
      setDelelteId("");
    } catch (error) {
      console.error("Error deleting session:", error);
      setWantDelete(false);
      setDelelteId("");
    }
  };

  const startIndex = currentPage * itemsPerPage;
  const paginatedData = filteredData?.slice(startIndex, startIndex + itemsPerPage);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  return (
    <Layout>
      <div className="h-full overflow-y-auto text-xs md:text-sm lg:text-base px-5 mt-2 relative pb-24">
  <table className="border-collapse w-full table-auto border">
    <thead className="bg-green-800 border text-white lg:text-base text-xs sticky top-0">
      <tr>
        <th className="px-4 py-2 sm:py-3 sm:px-6 text-center pl-5" style={{ paddingLeft: 10 }}>
          Name
        </th>
        <th className="px-4 py-2 sm:py-3 sm:px-6 text-center pl-5" style={{ paddingLeft: 10 }}>
          Email
        </th>
        <th className="px-4 py-2 sm:py-3 sm:px-6 text-center pl-5" style={{ paddingLeft: 10 }}>
          Phone No.
        </th>
        <th className="sm:table-cell px-4 py-2 sm:py-3 sm:px-6 text-center pl-5" style={{ paddingLeft: 10 }}>
          Age
        </th>
        <th className="sm:table-cell px-4 py-2 sm:py-3 sm:px-6 text-center pl-5" style={{ paddingLeft: 10 }}>
          Gender
        </th>
        <th className="sm:table-cell px-4 py-2 sm:py-3 sm:px-6 text-center pl-5" style={{ paddingLeft: 10 }}>
          Height
        </th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody className="text-zinc-500 px-10 text-center">
      {paginatedData && paginatedData.length > 0
        ? paginatedData.map((item, index) => (
            <tr key={index} className="hover:text-black cursor-pointer hover:bg-green-50/50 capitalize">
              <td className="px-4 py-2 sm:py-3 sm:px-6" style={{ paddingLeft: 10 }}>
                {item?.data?.name}
              </td>
              <td className="px-4 py-2 sm:py-3 sm:px-6" style={{ paddingLeft: 10 }}>
                {item?.data?.email}
              </td>
              <td className="px-4 py-2 sm:py-3 sm:px-6" style={{ paddingLeft: 10 }}>
                {item?.data?.phone}
              </td>
              <td className="sm:table-cell px-4 py-2 sm:py-3 sm:px-6" style={{ paddingLeft: 10 }}>
                {item?.data?.age}
              </td>
              <td className=" sm:table-cell px-4 py-2 sm:py-3 sm:px-6" style={{ paddingLeft: 10 }}>
                {item?.data?.gender}
              </td>
              <td className=" sm:table-cell px-4 py-2 sm:py-3 sm:px-6" style={{ paddingLeft: 10 }}>
                {item?.data?.height}
              </td>
              <td>
                <div className="flex justify-center items-center">
                  <RiDeleteBinLine
                    className="text-red-500 hover:bg-red-50 border border-transparent hover:border-red-300 p-2 text-4xl rounded-lg"
                    onClick={() => {
                      setWantDelete(true);
                      setDelelteId(item?.sessionId);
                    }}
                  />
                </div>
              </td>
            </tr>
          ))
        : [...Array(5)].map((_, key) => (
            <tr key={key}>
              {[...Array(7)].map((__, i) => (
                <td key={i} className="px-4 py-2 sm:py-3 sm:px-6">
                  <SkeletonContainer data="loading..." />
                </td>
              ))}
            </tr>
          ))}
    </tbody>
  </table>

  {wantDelete && (
    <div className="flex justify-center items-center w-full h-full fixed top-0 left-0 right-0 bottom-0 z-50">
      <div className="backdrop-blur-sm w-2/3 sm:w-1/2 h-2/3 shadow flex justify-center items-center rounded-lg">
        <div className="border rounded-lg p-5 lg:px-10 capitalize bg-white">
          <div className="font-semibold text-lg">Do you really want to delete?</div>
          <div className="flex justify-between p-5 gap-3">
            <button
              className="p-2 px-6 border border-green-200 bg-green-50 text-green-500 font-semibold rounded-md"
              onClick={() => DeleteHandler(DelelteId)}
            >
              Yes
            </button>
            <button
              className="p-2 px-6 border border-red-200 bg-red-50 text-red-500 font-semibold rounded-md"
              onClick={() => {
                setWantDelete(false);
                setDelelteId("");
              }}
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  )}

  <div className="py-5 fixed bottom-1 flex justify-center left-7 bg-white w-full h-16">
    {filteredData && (
      <div className="">
        <ReactPaginate
          pageCount={Math.ceil(filteredData.length / itemsPerPage)}
          pageRangeDisplayed={10}
          marginPagesDisplayed={2}
          onPageChange={handlePageClick}
          containerClassName="pagination"
          activeClassName="active bg-green-800 lg:p-2 py-0.5 lg:px-4 px-2 rounded text-white"
          previousLabel={"Previous"}
          nextLabel={"Next"}
          nextClassName="hover:bg-green-200 lg:p-2 py-0.5 lg:px-4 px-2 rounded hover:text-green-800 border border-green-800 "
          previousClassName="hover:bg-green-200 lg:p-2 py-0.5 lg:px-4 px-2 rounded hover:text-green-800 border border-green-800"
          pageClassName="lg:p-2 py-0.5 lg:px-4 px-2 text-sm lg:text-base"
          className="flex lg:gap-7 md:gap-5 gap-3"
        />
      </div>
    )}
  </div>
</div>

    </Layout>
  );
};

export default Sessions;
