import React, { useEffect, useState } from "react";
import Logo from "../components/logo";
import { MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";
import axios from "axios";
import { apiurl } from "../config/config";
import Cookies from "js-cookie";
import Loader from "../components/loader";
import { CiWarning } from "react-icons/ci";
import { IoWarningOutline } from "react-icons/io5";
import { Link } from "react-router-dom";

const payload = {
  email: "",
  password: "",
};

const Login = () => {
  const [inputData, setInputData] = useState(payload);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const InputHandler = (e) => {
    const { name, value } = e.target;
    setInputData({ ...inputData, [name]: value });
  };
  const [showPassword, setShowPassword] = useState(false);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 3000);
  // }, [loading]);

  const LoginHandler = (e) => {
    e.preventDefault();
    if (inputData.email && inputData.password) {
      setLoading(true);
      setMessage("")
      try {
        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${apiurl}/api/v1/user/login`,
          headers: {
            "Content-Type": "application/json",
          },
          data: JSON.stringify(inputData),
        };

        axios
          .request(config)
          .then((response) => {
            console.log("====>",JSON.stringify(response.data));
            const token = response.data.token;
            if (token) {
              Cookies.set("token", token, { expires: 1, secure: true });
              window.location.reload();
              setLoading(false);
            } else {
              setMessage(response.data?.message);
              setLoading(false);
            }
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    } else{
      setMessage("email and password both required")
    }
  };
  return (
    <div className="h-screen bg-zinc-50 flex justify-center items-center">
      <div className="bg-white shadow-lg  px-12 py-10  rounded-lg w-11/12 md:w-2/5 xl:w-3/12">
        <Link to='/' className="mb-7 flex justify-center">
          <Logo className="lg:h-14" />
        </Link>
        <h1 className="text-sm font-medium">Login your account.</h1>
        <div className="mt-3">
          <form className="flex flex-col gap-4" onSubmit={LoginHandler}>
            <input
              name="email"
              type="email"
              className="bg-zinc-50 outline-none font-medium text-zinc-600 focus:drop-shadow-sm lg:text-base text-xs w-full py-3 rounded-md border px-4"
              placeholder="Email"
              onChange={InputHandler}
            />
            <div className="bg-zinc-50  font-medium text-zinc-600  lg:text-base text-xs w-full py-3 rounded-md border px-4 flex justify-between items-center">
              <input
                name="password"
                type={showPassword ? "text" : "password"}
                className="outline-none bg-transparent w-full pr-4"
                placeholder="Password"
                onChange={InputHandler}
              />
              {inputData.password && (
                <div
                  className=" cursor-pointer"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <MdOutlineVisibility />
                  ) : (
                    <MdOutlineVisibilityOff className="opacity-60" />
                  )}
                </div>
              )}
            </div>
            {message && (
              <div className=" bg-red-100 border-red-500 text-red-500 font-medium   py-1 rounded-md flex justify-center">
                <p className="flex gap-1 items-center justify-center text-[0.6rem] md:text-xs">
                  <IoWarningOutline />
                  {message}
                </p>
              </div>
            )}
            <div className="flex justify-center mt-7 relative">
             
                <button
                 
                  className={` bg-zinc-950 text-white px-4 w-32 h-10 py-2 rounded-lg flex justify-center items-center relative `}
                  type="submit"
                  disabled={loading === true}

                >
                  {loading ? <Loader  className="w-14 h-14" /> : "Log in"}
                </button>
              {/* )} */}
            </div>
            <div className="text-sm text-gray-500 flex justify-end"><Link to='/forget-password'>Forget Password ?</Link></div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
