import React from "react";
import Logo from "../components/logo";
import { NavLink } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="h-screen flex justify-center items-center bg-zinc-50">
      <div className="px-20 py-52 gap-10 relative  flex flex-col justify-center items-center bg-white  rounded-3xl shadow-md">
        <Logo className="h-10 lg:h-20" />
        <div className="text-lg font-bold uppercase absolute bottom-5 text-center text-green-900 ">
      
            <p>Page not found</p> <NavLink className="text-sm border-b border-blue-900 text-blue-900" to="/">back to home</NavLink>
            </div>
      </div>
    </div>
  );
};

export default NotFound;
