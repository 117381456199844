import React, { useState } from "react";
import Logo from "../components/logo";
import { IoWarningOutline } from "react-icons/io5";
import axios from "axios";
import Loader from "../components/loader";
import { Link, useNavigate, useParams } from "react-router-dom";

const UpdatePassword = () => {
  const _navigate= useNavigate()
  const { userid, token } = useParams();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false); 

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!newPassword || !confirmPassword) {
      setMessage("Both password fields are required.");
      setIsSuccess(false); 
      return;
    }

    if (newPassword !== confirmPassword) {
      setMessage("Passwords do not match.");
      setIsSuccess(false); 
      return;
    }

    const payload = {
      password: newPassword,
      userid: userid,
    };

    setLoading(true);
    setMessage("");

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://api.thehealthspanco.com/api/v1/user/verifyPasswordToken",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
        password: newPassword,
        userid: userid,
      },
      data: {},
    };

    try {
      const response = await axios.request(config);
     console.log("reset  password response???",response)
      if(response.data.success === true){
        setIsSuccess(response.data.success);
        setMessage("Password updated successfully!");
         setTimeout(()=>{
          _navigate("/")
         },3000)
      }else{
        setMessage("Unauthorized Person");
      }
       
    } catch (error) {
      setMessage("An error occurred while updating the password.");
      setIsSuccess(false);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="h-screen bg-zinc-50 flex justify-center items-center">
      <div className="bg-white shadow-lg px-12 py-10 rounded-lg w-11/12 md:w-2/5 xl:w-3/12">
        <Link to="/" className="mb-7 flex justify-center">
          <Logo className="lg:h-14" />
        </Link>
        <h1 className="text-sm font-medium">Enter your new password.</h1>
        <div className="mt-3">
          <form className="flex flex-col gap-4" onSubmit={handleSubmit}>
            <input
              name="newpass"
              type="text"
              className="bg-zinc-50 outline-none font-medium text-zinc-600 focus:drop-shadow-sm lg:text-base text-xs w-full py-3 rounded-md border px-4"
              placeholder="New Password"
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <input
              name="confirmpass"
              type="text"
              className="bg-zinc-50 outline-none font-medium text-zinc-600 focus:drop-shadow-sm lg:text-base text-xs w-full py-3 rounded-md border px-4"
              placeholder="Confirm Password"
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            {message && (
              <div
                className={`font-medium py-1 rounded-md flex justify-center ${
                  isSuccess
                    ? "text-green-500 bg-green-100 border-green-500"
                    : "text-red-500 bg-red-100 border-red-500"
                }`}
              >
                <p className="flex gap-1 items-center justify-center text-[0.6rem] md:text-xs">
                  <IoWarningOutline />
                  {message}
                </p>
              </div>
            )}
            <div className="flex justify-center mt-7 relative">
              <button
                className={`bg-zinc-950 text-white px-4 h-10 py-2 rounded-lg flex justify-center items-center relative`}
                type="submit"
                disabled={loading}
              >
                {loading ? <Loader className="w-14 h-14" /> : "Update Password"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UpdatePassword;
