import React from 'react'
import Layout from '../components/layout'
import CommingSoon from '../components/commingSoon'

const Support = () => {
  return (
    <Layout>
    
      <CommingSoon/>
    </Layout>
  )
}

export default Support
