import React from "react";
import Layout from "../components/layout";
import { data } from "../components/dummydata";
import CommingSoon from "../components/commingSoon";

const Orders = () => {
  return (
    <Layout>
      {/* <table className="border-collapse table-auto w-full mt-5">
        <thead className="bg-zinc-50 border-y text-zinc-700 lg:text-base text-xs">
          <tr>
            <th className="px-4 py-2 sm:py-3 sm:px-6">Name</th>
            <th className="px-4 py-2 sm:py-3 sm:px-6">Email</th>
            <th className="px-4 py-2 sm:py-3 sm:px-6">Phone No.</th>
            <th className="hidden sm:table-cell px-4 py-2 sm:py-3 sm:px-6">
              Product
            </th>
            <th className="hidden sm:table-cell px-4 py-2 sm:py-3 sm:px-6">
              Quantity
            </th>
            <th className="hidden sm:table-cell px-4 py-2 sm:py-3 sm:px-6">
              Address
            </th>
            <th className="hidden sm:table-cell px-4 py-2 sm:py-3 sm:px-6">
              Payment Method
            </th>
          </tr>
        </thead>
        <tbody className="text-zinc-400">
          {data.map((item, index) => {
            return (
              <tr
                key={index}
                className="text-center hover:text-black cursor-pointer"
              >
                <td className="px-4 py-2 sm:py-3 sm:px-6">{item.name}</td>
                <td className="px-4 py-2 sm:py-3 sm:px-6">{item.email}</td>
                <td className="px-4 py-2 sm:py-3 sm:px-6">{item.phone}</td>
                <td className="hidden sm:table-cell px-4 py-2 sm:py-3 sm:px-6">
                  heart attack machine
                </td>
                <td className="hidden sm:table-cell px-4 py-2 sm:py-3 sm:px-6">
                  10
                </td>
                <td className="hidden sm:table-cell px-4 py-2 sm:py-3 sm:px-6">
                  Noida, kala kua ke pass uske left side wali gali
                </td>
                <td className="hidden sm:table-cell px-4 py-2 sm:py-3 sm:px-6">
                  Credit Card
                </td>
              </tr>
            );
          })}
        </tbody>
      </table> */}

      <CommingSoon/>
    </Layout>
  );
};

export default Orders;
