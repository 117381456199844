import React, { useEffect, useState } from "react";
import Layout from "../components/layout";
import axios from "axios";
import { apiurl } from "../config/config";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchData } from "../redux/slices/diagnosPatient";
import SkeletonContainer from "../routes/skeleton";
import { useFormatDate, useFormatOnlyDate } from "../hooks/useFormatDate";
import moment from "moment";
import { GoDownload } from "react-icons/go";
import ExportDataHandler from "../components/exportDataHandler";
import ReactPaginate from "react-paginate";
import { FaPencilAlt } from "react-icons/fa";

const Diagnosis = () => {
  const [diagnosData, setDiagnosData] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = useState(null); 
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 50; 

  const [date, setDate] = useState({
    from: "",
    till: "",
  });

  useEffect(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${apiurl}/api/v1/diagnose/getall`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        if (response.data.success === true) {
          setDiagnosData(response.data.data);
          setFilteredData(response.data.data);
          dispatch(fetchData(response.data.data));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const getCurrentDate = () => {
    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = currentDate.getMonth() + 1;
    const year = currentDate.getFullYear();
    const padZero = (num) => (num < 10 ? `0${num}` : num);
    let formattedDate = `${padZero(day)}-${padZero(month)}-${year}`;
    return formattedDate.split("-").reverse().join("-");
  };

  const FormateDate = (date) => useFormatOnlyDate(date);
  const FormateOnlyDate = (date) => useFormatOnlyDate(date);

  const dateHandler = (e) => {
    const { name, value } = e.target;
    setDate({ ...date, [name]: value });
  };

  const formattedDate = (date) => moment(date).format("YYYY-MM-DD ");

  useEffect(() => {
    if (date.from && date.till) {
      FilterHandler(); 
    }
  }, [date]);
 

  const FilterHandler = () => {
    if (diagnosData) {
      const fromDate = new Date(date.from);
      const tillDate = new Date(date.till);

      const filtered = diagnosData.filter((item) => {
        const diagnosisDate = new Date(
          moment(item?.diagonse_date).format("YYYY-MM-DD")
        );
        return diagnosisDate >= fromDate && diagnosisDate <= tillDate;
      });

      setFilteredData(filtered);
      setCurrentPage(0); 
    }
  };

  const ExportPayload = (data) => {
    const payload = Array.isArray(data) ? data : [data];
    return payload.map((item) => {
      return {
        _id: item._id,
        name: item.name,
        email: item.email,
        phone: item.phone,
        date: item.date,
        age: item.age,
        gender: item.gender,
        weight: item.weight,
        height: item.height,
        health_goals: Array.isArray(item.health_goals)
          ? item.health_goals.join(", ")
          : "",
        bmi_category: item.bmi?.category || "",
        bmi_range: item.bmi?.range || "",
        gut_symptom: Array.isArray(item.gut_symptom)
          ? item.gut_symptom.join(", ")
          : "",
        exercise: item.exercise || "",
        phyiscal_activity: item.phyiscal_activity || "",
        medical_condition: item.medical_condition || "",
        root_cause: Array.isArray(item.root_cause)
          ? item.root_cause.join(", ")
          : "",
        bmr: item.bmr || "",
        idealWeight: item.idealWeight || "",
        weightToLose: item.weightToLose || "",
        weightLossDuration: item.weightLossDuration || "",
        idealWaistSize: item.idealWaistSize || "",
        dailyCalorieIntake: item.dailyCalorieIntake || "",
        diagnosed_conditions: Array.isArray(item.diagnosed_conditions)
          ? item.diagnosed_conditions.join(", ")
          : "",
        eating_habits: item.eating_habits || "",
        allergic: Array.isArray(item.allergic) ? item.allergic.join(", ") : "",
        medication: item.medication || "",
        sleep: item.sleep || "",
        diagonse_date: item.diagonse_date || "",
        description: item.description || "",
        createdAt: item.createdAt || "",
        updatedAt: item.updatedAt || "",
      };
    });
  };

  const startIndex = currentPage * itemsPerPage;
  const paginatedData =
    filteredData && filteredData.slice(startIndex, startIndex + itemsPerPage);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };
  return (
    <Layout>
      <div className="w-full mt-2 h-full overflow-y-auto">
  <div className="my-3 mt-6 flex justify-end relative ">
    
    <div className="flex gap-3 max-[375px]:justify-between">
      <input
        placeholder="From"
        name="from"
        type="date"
        max={getCurrentDate()}
        className="outline-none border border-green-800 rounded-lg md:text-sm text-xs px-1 md:px-3 lg:px-5 py-2 w-full md:w-auto"
        onChange={dateHandler}
        value={date.from}
      />
      <input
        placeholder="Till"
        name="till"
        type="date"
        min={date.from}
        max={getCurrentDate()}
        className="outline-none border border-green-800 rounded-lg md:text-sm text-xs px-1 md:px-3 lg:px-5 py-2 w-full md:w-auto"
        onChange={dateHandler}
        value={date.till}
      />
    </div>

   
    <button
       className={`border p-2 px-4 ms-2 rounded-md ${
              date.from && date.till && filteredData.length > 0
                ? "bg-green-50 border-green-800 text-green-800"
                : "border-green-800 text-green-800"
            }`}
      onClick={() => ExportDataHandler(ExportPayload(filteredData))}
      disabled={!(date.from && date.till && filteredData?.length > 0)}
    >
      <GoDownload />
    </button>
  </div>

  
  <div className="overflow-auto text-xs md:text-sm lg:text-base px-2 md:px-5 w-full">
    <table className=" w-full relative table-auto overflow-auto border">
      <thead className="bg-green-800  text-white text-xs md:text-sm sticky top-0 py-5">
        <tr>
          <th className="px-2 py-1 sm:px-4 md:px-6 lg:px-8 text-center">Name</th>
          <th className="px-2 py-1 sm:px-4 md:px-6 lg:px-8 text-center">Email</th>
          <th className="px-2 py-1 sm:px-4 md:px-6 lg:px-8 text-center">Phone</th>
          <th className=" md:table-cell px-2 py-1 sm:px-4 md:px-6 lg:px-8 text-center">Date</th>
          <th className=" md:table-cell px-2 py-1 sm:px-4 md:px-6 lg:px-8 text-center">B.M.R</th>
          <th className=" md:table-cell px-2 py-1 sm:px-4 md:px-6 lg:px-8 text-center">B.M.I</th>
          <th className=" md:table-cell px-2 py-1 sm:px-4 md:px-6 lg:px-8 text-center">Purchase</th>
          <th className=" md:table-cell px-2 py-1 sm:px-4 md:px-6 lg:px-8 text-center">Action</th>
        </tr>
      </thead>
      <tbody className="text-zinc-500">
        {paginatedData && paginatedData.length > 0
          ? paginatedData.map((item, index) => (
              <tr key={index} className="hover:bg-green-50/50"  onClick={() => navigate(`/diagnosis/${item._id}`, { state: { patient: item } })}>
                <td className="px-2 py-1 sm:px-4 md:px-6 text-center">{item?.name}</td>
                <td className="px-2 py-1 sm:px-4 md:px-6 text-center">{item?.email}</td>
                <td className="px-2 py-1 sm:px-4 md:px-6 text-center">{item?.phone}</td>
                <td className=" md:table-cell px-2 py-1 sm:px-4 md:px-6 text-center">
                  {FormateDate(item?.createdAt)}
                </td>
                <td className=" md:table-cell px-2 py-1 sm:px-4 md:px-6 text-center">{item?.bmr}</td>
                <td className="md:table-cell px-2 py-1 sm:px-4 md:px-6 text-center">{item?.bmi?.range}</td>
                <td className="md:table-cell px-2 py-1 sm:px-4 md:px-6 text-center">
                  <span className={item?.orderDetails?.purchase ? "text-green-500" : "text-red-500"}>
                    {item?.orderDetails?.purchase ? "Yes" : "No"}
                  </span>
                </td>
                <td className=" md:table-cell px-2 py-1 sm:px-4 md:px-6 text-center text-black ">
                  <FaPencilAlt
                   
                    className="cursor-pointer m-auto"
                  />
                </td>
              </tr>
            ))
          : [...Array(5)].map((_, key) => (
              <tr key={key}>
                <td className="px-2 py-1 sm:px-4 md:px-6">
                  <SkeletonContainer data="loading..." />
                </td>
                <td className="px-2 py-1 sm:px-4 md:px-6">
                  <SkeletonContainer data="loading..." />
                </td>
                <td className="px-2 py-1 sm:px-4 md:px-6">
                  <SkeletonContainer data="loading..." />
                </td>
                <td className="px-2 py-1 sm:px-4 md:px-6">
                  <SkeletonContainer data="loading..." />
                </td>
                <td className="px-2 py-1 sm:px-4 md:px-6">
                  <SkeletonContainer data="loading..." />
                </td>
                <td className="px-2 py-1 sm:px-4 md:px-6">
                  <SkeletonContainer data="loading..." />
                </td>
                <td className="px-2 py-1 sm:px-4 md:px-6">
                  <SkeletonContainer data="loading..." />
                </td>
                <td className="px-2 py-1 sm:px-4 md:px-6">
                  <SkeletonContainer data="loading..." />
                </td>
                
              </tr>
            ))}
      </tbody>
    </table>
  </div>


  {filteredData && (
  <div className="flex justify-center py-5 mt-5">
    <ReactPaginate
      pageCount={Math.ceil(filteredData.length / itemsPerPage)}
      pageRangeDisplayed={window.innerWidth < 640 ? 3 : 5}  
      marginPagesDisplayed={window.innerWidth < 640 ? 1 : 2} 
      onPageChange={handlePageClick}
      containerClassName="pagination flex flex-wrap justify-center gap-2 md:gap-4 w-full"
      activeClassName="active bg-green-800 p-1 md:p-2 px-2 md:px-4 rounded text-white"
      previousLabel={"Previous"}
      nextLabel={"Next"}
      nextClassName="hover:bg-green-200 p-1 md:p-2 px-2 md:px-4 rounded   hover:text-green-800 border border-green-800"
      previousClassName="hover:bg-green-200 p-1 md:p-2 px-2 md:px-4 rounded   hover:text-green-800 border border-green-800"
      pageClassName="p-1 px-2 text-xs md:text-sm"
      className="flex flex-wrap justify-center md:gap-5 gap-2"
    />
  </div>
)}
</div>

    </Layout>
  );
};

export default Diagnosis;
