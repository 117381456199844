import { cardMenus } from "./mapData";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ProfileLogo from "./profileLogo";
import { NavLink, useNavigate } from "react-router-dom";
import { profieCardHandler } from "../redux/slices/handler";
import Cookies from "js-cookie";

const ProfileCard = () => {
    const userData = useSelector((state) => state.userData);
    const dispatch = useDispatch();
    const navigation = useNavigate();
    const LogoutHandler = () => {
      Cookies.remove("token");
      window.location.reload();
      navigation("/");
    };
  
    return (
      <div className=" absolute  z-50 w-full h-full  flex justify-end items-start">
        <div
          className="w-full h-full absolute -z-0 backdrop-blur-sm"
          onClick={() => dispatch(profieCardHandler(false))}
        ></div>
        <div className="shadow bg-white p-5 rounded-lg min-w-72 flex flex-col gap-6 z-10 m-2">
          <div className="flex items-center gap-2 ">
            <ProfileLogo className="w-9 h-9" />
            <div className="">
              <p className="font-medium">
                {userData?.firstname} {userData?.lastname}
              </p>
              <p className="text-sm text-zinc-400">{userData?.email}</p>
            </div>
          </div>
          <div className="flex flex-col gap-1">
            {cardMenus.map((item, index) => {
              return (
                <NavLink
                  key={index}
                  to={item.to && item.to}
                  onClick={item.onClick ? LogoutHandler : null}
                >
                  <p
                    className="flex items-center gap-3 text-sm border border-transparent hover:border-zinc-200 py-2 px-2 rounded-lg hover:shadow-sm"
                    onClick={() => dispatch(profieCardHandler(false))}
                  >
                    <span className="text-base lg:text-lg"> {item?.icon}</span>
                    {item?.title}
                  </p>
                </NavLink>
              );
            })}
          </div>
        </div>
      </div>
    );
  };
  
  export default ProfileCard;


 