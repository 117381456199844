import React from 'react'

import PrivateRoute from '../config/protectedRoute'

const RoutesContainer = () => {
  return (
   <PrivateRoute/>
  )
}

export default RoutesContainer