import React from 'react'
import Layout from '../components/layout'
import { GoDownload } from 'react-icons/go'
import SkeletonContainer from '../routes/skeleton'

const Calendar = () => {
  return (
    <Layout>
      <div>
        <div className="my-3 mt-6 flex justify-end relative mx-10 gap-2">
          <div className="flex gap-3 max-[375px]:justify-between">
            <input
              placeholder="from"
              name="from"
              type="date"

              className="outline-none border rounded-lg md:text-base text-xs px-0.5 max-[375px]:w-full max-[375px]:px-3 sm:px-2 lg:px-5 py-2 sm:w-auto"

            />
            <input
              placeholder="till"
              name="till"
              type="date"


              className="outline-none border rounded-lg md:text-base text-xs px-0.5 max-[375px]:w-full max-[375px]:px-3 sm:px-2 lg:px-5 py-2 sm:w-auto"

            />
          </div>
          <button
            className="border p-2 px-4 rounded-md">
            <GoDownload />
          </button>
        </div>
        <div className="h-[75%] overflow-scroll text-xs md:text-sm lg:text-base ">
          <table className="border-collapse w-full relative table-auto overflow-auto">
            <thead className="bg-zinc-50 border-y text-zinc-700 lg:text-base text-xs sticky top-0">
              <tr>
                <th className="px-4 py-2 sm:py-3 sm:px-6 text-start pl-5" style={{ paddingLeft: 10, paddingRight: 10 }}>
                  Name
                </th>
                <th className="px-4 py-2 sm:py-3 sm:px-6 text-start pl-5" style={{ paddingLeft: 10, paddingRight: 10 }}>
                  Email
                </th>
                <th className="px-4 py-2 sm:py-3 sm:px-6 text-start pl-5" style={{ paddingLeft: 10, paddingRight: 10 }}>
                  Phone No.
                </th>
                <th className="hidden sm:table-cell px-4 py-2 sm:py-3 sm:px-6 text-start pl-5" style={{ paddingLeft: 10, paddingRight: 10 }}>
                  Date
                </th>
                <th className="hidden sm:table-cell px-4 py-2 sm:py-3 sm:px-6 text-start pl-5" style={{ paddingLeft: 10, paddingRight: 10 }}>
                  B.M.R
                </th>
                <th className="hidden sm:table-cell px-4 py-2 sm:py-3 sm:px-6 text-start pl-5" style={{ paddingLeft: 10, paddingRight: 10 }}>
                  B.M.I
                </th>
              </tr>
            </thead>
            <tbody className="text-zinc-500 px-10 text-start">
            
                    <tr
                      
                      className="hover:text-black cursor-pointer hover:bg-zinc-50/50"
                     
                    >
                      <td className=" py-2 sm:py-3 " style={{paddingLeft:10}}>
                        
                      </td>
                      <td className=" py-2 sm:py-3 " style={{paddingLeft:10}}>
                        
                      </td>
                      <td className=" py-2 sm:py-3 " style={{paddingLeft:10}}>

                      </td>
                      <td className="hidden sm:table-cell  py-2 sm:py-3 " style={{paddingLeft:10}}>
                        
                      </td>
                      <td className="hidden sm:table-cell  py-2 sm:py-3 " style={{paddingLeft:10}}>
                       
                      </td>
                      <td className="hidden sm:table-cell  py-2 sm:py-3 " style={{paddingLeft:10}}>
                        
                      </td>
                    </tr>
                    <tr >
                      <td className="px-4 py-2 sm:py-3 sm:p-3">
                        <SkeletonContainer data="loading..." />
                      </td>
                      <td className="px-4 py-2 sm:py-3 sm:p-3">
                        <SkeletonContainer
                          data="loading..."
                          className="w-4/5 ml-2"
                        />
                      </td>
                      <td className="px-4 py-2 sm:py-3 sm:p-3">
                        <SkeletonContainer
                          data="loading..."
                          className="w-4/5 ml-2"
                        />
                      </td>
                      <td className="px-4 py-2 sm:py-3 sm:p-3">
                        <SkeletonContainer
                          data="loading..."
                          className="w-4/5 ml-2"
                        />
                      </td>
                      <td className="px-4 py-2 sm:py-3 sm:p-3">
                        <SkeletonContainer
                          data="loading..."
                          className="w-4/5 ml-2"
                        />
                      </td>
                      <td className="px-4 py-2 sm:py-3 sm:p-3">
                        <SkeletonContainer
                          data="loading..."
                          className="w-4/5 ml-2"
                        />
                      </td>
                    </tr>
                 
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  )
}

export default Calendar