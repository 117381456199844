import React, { useState } from "react";
import Layout from "../components/layout";
import ProfileLogo from "../components/profileLogo";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Profile = () => {
  const navigation = useNavigate();
  const LogoutHandler = () => {
    Cookies.remove("token");
    window.location.reload();
    navigation("/");
  };
  const userData = useSelector((state) => state?.userData);
  console.log("This is user data", userData);

  const permission = [
    { Dashboard: userData.permissions.dashboard },
    { Diagnosis: userData.permissions.diagnosis },
    { Sessions: userData.permissions.sessions },
    { Leads: userData.permissions.leads },
    { Orders: userData.permissions.orders },
    { Consultant: userData.permissions.consultants },
    { Calendar: userData.permissions.calendar },
    { Appointment: userData.permissions.appointments },
    { AddTeam: userData.permissions.addteam },
    { Team: userData.permissions.team },
  ];
  const [permissions, setPermissions] = useState(permission);

  return (
    <Layout>
      <div>
        <div className="flex justify-end lg:justify-center lg:flex-row flex-col-reverse relative h-screen w-full overflow-y-scroll ">
          <div className=" px-3 lg:px-10 mt-5 lg:mt-20 lg:w-1/2  flex flex-col gap-2 lg:gap-5  ">
            <div className="flex  justify-center flex-row  gap-3">
              <Identity label="First Name"> {userData?.firstname}</Identity>
              <Identity label="Last Name ">{userData?.lastname}</Identity>
            </div>
            <Identity label="Email">{userData?.email}</Identity>
            <Identity label="Phone"> {userData?.phone}</Identity>
            <Identity label="Age">{userData?.age}</Identity>
            {/* Permission */}
            <div className="mt-3  pb-32">
              <table className="w-full border-none">
                <tbody>
                  {permissions.map((val, key) => {
                    const permissionName = Object.keys(val)[0];
                    const permissionValue = val[permissionName];

                    return (
                      <tr key={key}>
                        <td className="text-sm font-normal border-none teamborder text-start">
                          {permissionName}
                        </td>
                        <td className="border-none teamborder">
                          <label class="inline-flex items-center cursor-pointer">
                            <input
                              type="checkbox"
                              value=""
                              class="sr-only peer"
                              checked={permissionValue}
                              disabled
                              onChange={() => {}}
                            />
                            <div class="relative w-8 h-4 bg-gray-500 rounded-full peer dark:bg-gray-400 peer-checked:bg-green-500 peer-checked:after:translate-x-4 after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-3 after:w-3 after:transition-all dark:border-gray-600"></div>
                          </label>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>

          <div className="lg:w-1/3 flex flex-col gap-5 items-center mt-10 lg:m-0 lg:items-end ">
            <div className=" lg:w-full flex flex-col items-center justify-center  gap-5 lg:mt-20 lg:p-10 p-5  bg-zinc-50 rounded-xl border">
              {/* profile */}
              <ProfileLogo className="lg:h-52 h-36 lg:w-52" />
              <div className="bg-white px-10 py-2 flex gap-2 font-medium text-[0.6rem] lg:text-sm rounded-lg shadow">
                <p className="text-zinc-600">HSID : </p>
                <p className="text-zinc-500 ">{userData?.userid}</p>
              </div>
            </div>
            {/* log out */}
            <button
              className="bg-red-100 px-10 py-2 text-red-400 font-medium text-xs lg:text-sm rounded-lg border border-red-200  hidden lg:block"
              onClick={LogoutHandler}
            >
              Log out
            </button>
          </div>
        </div>
        <div className="flex justify-center mt-10 lg:mt-28  lg:hidden">
          <button
            className="bg-red-100 px-10 py-2 text-red-500 font-medium rounded-lg border border-red-300"
            onClick={LogoutHandler}
          >
            Log out
          </button>
        </div>
      </div>
    </Layout>
  );
};

export default Profile;

const Identity = ({ label, children }) => {
  return (
    <div className="flex flex-col w-full text-xs lg:text-base">
      <label className="text-[0.7rem] lg:text-sm  text-zinc-500 font-medium">
        {label}
      </label>
      <p className="bg-zinc-50 py-2 rounded-lg px-4 text-zinc-400 border w-full">
        {children}
      </p>
    </div>
  );
};
