import { createSlice } from "@reduxjs/toolkit";

export const userHandler = createSlice({
  name: "userHandler",
  initialState: {},
  reducers: {
    userDataHandler: (state, action) => {
      console.log("Data saved in store successfully");
      return action.payload; 
    }
  },
});

export const { userDataHandler } = userHandler.actions;

export default userHandler.reducer;
