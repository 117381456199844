import React, { useEffect, useState } from "react";
import Logo from "../components/logo";
import { IoWarningOutline } from "react-icons/io5";
import axios from "axios";

import Loader from "../components/loader";
import { Link } from "react-router-dom";

const Forgetpassword = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);  
  const [showPopup, setShowPopup] = useState(false)

  const LoginHandler = (e) => {
    e.preventDefault();
    const payload = {
      email: email
    }
    if (email) {
      setLoading(true);
      setMessage("")
      try {
        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: "https://api.thehealthspanco.com/api/v1/user/passwordToken",
          headers: {
            "Content-Type": "application/json",
          },
          data: JSON.stringify(payload),
        };

        axios
          .request(config)
          .then((response) => {
            console.log("====", JSON.stringify(response.data));
            const success = response.data.link;
            if (success) {
              setShowPopup(true);
              setLoading(false);
              setMessage(response.data.message);
              setIsSuccess(true);  
              console.log("response.data.success", response.data.success)
            } else {
              setLoading(false);
            }
          })
          .catch((error) => {
            console.log(error);
            setMessage("Failed to send the reset link.");
            setIsSuccess(false);  
            setLoading(false);
          });
      } catch (error) {
        console.log(error);
        setMessage("An error occurred.");
        setIsSuccess(false);  
        setLoading(false);
      }
    } else {
      setMessage("Email is required");
      setIsSuccess(false);  
    }
  };

  useEffect(() => {
    if (showPopup) {
      const timer = setTimeout(() => {
        setShowPopup(false);
      }, 9000);
      return () => clearTimeout(timer);
    }
  }, [showPopup]);

  return (
    <div className="h-screen bg-zinc-50 p-10">
      {showPopup && (
        <div className="flex justify-center items-center pb-20">
          <div className="bg-green-100 border border-green-400 w-full mx-24 mt-5 p-5 rounded-md">
            <p className="capitalize text-green-400">
              Updated password link is sent to your email. Please check your email.
            </p>
          </div>
        </div>
      )}

      <div className="flex justify-center items-center">
        <div className="bg-white shadow-lg px-12 py-10 rounded-lg w-11/12 md:w-2/5 xl:w-3/12">
          <Link to="/" className="mb-7 flex justify-center">
            <Logo className="lg:h-14" />
          </Link>
          <h1 className="text-sm font-medium">Enter your email.</h1>
          <div className="mt-3">
            <form className="flex flex-col gap-4" onSubmit={LoginHandler}>
              <input
                name="email"
                type="email"
                className="bg-zinc-50 outline-none font-medium text-zinc-600 focus:drop-shadow-sm lg:text-base text-xs w-full py-3 rounded-md border px-4"
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
              />
              {message && (
                <div
                  className={`font-medium py-1 rounded-md flex justify-center 
                  ${isSuccess ? "text-green-500 bg-green-200 border border-green-500" : "text-red-500 bg-red-200 border border-red-500"}`}
                >
                  <p className="flex gap-1 items-center justify-center text-[0.6rem] md:text-xs">
                    <IoWarningOutline />
                    {message}
                  </p>
                </div>
              )}
              <div className="flex justify-center mt-7 relative">
                <button
                  className={`bg-zinc-950 text-white px-4 w-32 h-10 py-2 rounded-lg flex justify-center items-center relative`}
                  type="submit"
                  disabled={loading === true}
                >
                  {loading ? <Loader className="w-14 h-14" /> : "Send Link"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forgetpassword;
