import {createSlice } from "@reduxjs/toolkit";


export const diagnosePatient = createSlice({
  name: "diagnosis patients",
  initialState: {
    allPatients: "",
    pending: "",
    error: "",
  },

  reducers: {
    fetchData: (state, action) => {
      console.warn(action.payload);
      return action.payload;
    },
  },
});

export const { fetchData } = diagnosePatient.actions;

export default diagnosePatient.reducer;
