import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Dashboard from "../pages/dashboard";
import Forcasting from "../pages/forcasting";
import Calendar from "../pages/calendar";
import Note from "../pages/note";
import Actions from "../pages/actions";
import Gaps from "../pages/gaps";
import Contacts from "../pages/contacts";
import Leads from "../pages/leads";
import Opportunities from "../pages/opportunities";
import Accounts from "../pages/accounts";
import Diagnosis from "../pages/diagnosis";
import Orders from "../pages/orders";
import Login from "../pages/login";
import { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { apiurl } from "./config";
import NotFound from "../pages/notFound";
import Loader from "../components/loader";
import Profile from "../pages/profile";
import { useDispatch, useSelector } from "react-redux";
import { userDataHandler } from "../redux/slices/user";
import DiagnosisPatient from "../pages/diagnosisPatient";
import Appointment from "../pages/appointment";
import Consultant from "../pages/consultant";
import Help from "../pages/help";
import Support from "../pages/support";
import AddTeam from "../pages/addTeam";
import TeamList from "../pages/teamList";
import Sessions from "../pages/sessions";
import ShowConsultant from "../pages/showConsultant";
import Forgetpassword from "../pages/forgetpassword";
import Team from "../pages/team";
import UpdatePassword from "../pages/updatePassword";
import { useUser } from "../context/appcontext";
import TeamDetail from "../pages/teamDetail";

const PrivateRoute = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isTokenExpire, setIsTokenExpire] = useState(false);
  const token = Cookies.get("token");
  const dispatch = useDispatch();
  const { setUser } = useUser();
  const permission = useSelector((state) => state?.userData?.permissions);

  function verifyUer(token) {
    const config = {
      url: `${apiurl}/api/v1/user/verifyToken`,
      headers: {
        Authorization: token,
      },
      method: "post",
    };
    axios
      .request(config)
      .then((response) => {
        // console.log(response.data)
        return response.data;
      })
      .then((response) => {
        if (response.success === true) {
          // console.log("response.user", response.user);
          dispatch(userDataHandler(response.user));
          setIsAuthenticated(true);
          setIsTokenExpire(false);
          setUser(response.user);
        } else {
          Cookies.remove("token");
          setIsTokenExpire(true);
          setIsAuthenticated(false);
        }
        
      })
      .catch((error) => {
        setIsAuthenticated(false);
        setIsTokenExpire(true);

        Cookies.remove("token");
        console.error("Error: ", error);
      });
  }

  useEffect(() => {
    if (!token) {
      setIsAuthenticated(false);
    } else {
      verifyUer(token);
    }
  }, [token]);

  useEffect(() => {
    if (token === undefined || token === null) {
      setIsTokenExpire(true);
    } else {
      setIsTokenExpire(false);
    }
  }, [isAuthenticated, token]);

  // const selc =  useSelector((state) => state)
  // console.log(selc)
  const location = useLocation()
  const naviate = useNavigate()

  const pathName = location?.pathname.split("/")[1];
  console.log("pathName",pathName)


  const PermissionAuth = ({ element }) => {
    const navigate = useNavigate();
    const location = useLocation();
  
    
    const pathName = location?.pathname.split("/")[1];
  
    useEffect(() => {
      if (!permission[pathName]) {
        navigate("/"); 
      }
    }, [navigate, permission, pathName]);
  
    
    return permission[pathName] ? element : null;
  };





console.log("permissions",pathName,permission)


  return (
    <Routes>
      {isAuthenticated === false ? (
        <>
          <Route
            path="*"
            element={
              isTokenExpire ? <Login /> : <Loader className="w-14 h-14" />
            }
          />
             <Route path="/forget-password" element={<Forgetpassword />} />
             <Route path="/forget-password/:userid/:token" element={<UpdatePassword />} />
        </>
      ) : (
        <>
          <Route path="/"  element={<Dashboard />} />
          <Route path="/diagnosis" element={<PermissionAuth element={<Diagnosis />}/>} />
          <Route path="/diagnosis/:patientId" element={<PermissionAuth element={<DiagnosisPatient />}/>}/>
          <Route path="/forecasting" element={<Forcasting />} />
          <Route path="/calendar" element={<PermissionAuth element={<Calendar />}/>} />
          <Route path="/notes" element={<Note />} />
          <Route path="/actions" element={<Actions />} />
          <Route path="/gaps" element={<Gaps />} />
          <Route path="/opportunities" element={<Opportunities />} />
          <Route path="/accounts" element={<Accounts />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/leads" element={ <PermissionAuth element = {<Leads />}/>} />
          <Route path="/orders" element={<PermissionAuth element = {<Orders />}/>} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/appointments" element={<PermissionAuth element={<Appointment />}/>} />
          <Route path="/consultants" element={<PermissionAuth element={<Consultant />} /> } />
          <Route path="/consultant/:consultId" element={<PermissionAuth element={<ShowConsultant />} /> } />
          <Route path="/help" element={<Help />} />
          <Route path="/support" element={<Support />} />
          <Route path="/addteam" element={<PermissionAuth element={<AddTeam />}  /> }    />
          <Route path="forgetpassword" element={<Forgetpassword/>}/>
          <Route path="forgetpassword" element={<UpdatePassword/>}/>
          <Route path="/team" element={<PermissionAuth element={<TeamList />} /> }/>
          <Route path="/team/:teamId" element={<PermissionAuth element={<Team/>} /> }  />
          <Route path="/team/:teamId/edit" element={<PermissionAuth element={<TeamDetail/>} /> } />
          <Route path="/sessions" element={<PermissionAuth element={<Sessions />} /> }   />
          
          <Route path="*" element={<NotFound />} />
        </>
      )}
    </Routes>
  );
};

export default PrivateRoute;
