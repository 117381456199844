import React, { useState } from "react";
import SideNav from "./sideNav";
import Header from "./header";
import { useDispatch, useSelector } from "react-redux";
import ProfileCard from "./profileCard";
import { useHealthSpanContext, useUser } from "../context/appcontext";

const Layout = ({ children }) => {
  const handler = useSelector((state) => state.handler);
  

  let {userData} = useUser();
  console.log("userData 16",userData);

  return (
    <div className="w-full h-screen">
   
      <div className="flex  lg:flex-row flex-col relative h-full">
        <div
          className={`lg:w-1/6 w-[90%] h-full  border-zinc-200  lg:block   ${
            handler?.isOpenSideNav
              ? "block absolute top-9 border-none "
              : "hidden"
          } `}
        >
          <SideNav />
        </div>

        <div className="w-full lg:w-5/6 h-full  overflow-hidden">
          {/* header */}
          
          <Header className ='h-[8%] ' />

          <div className=" w-full h-[92%] relative ">
            {handler.isProfileCardOpen && <ProfileCard />}
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
