import { createSlice } from "@reduxjs/toolkit";

export const handlerSlice = createSlice({
  name: "handler",
  initialState: {
    isSettings: true,
    isTeamsStaff:true,
    isOpenSideNav: false,
    isProfileCardOpen: false,
  },
  reducers: {
    setingsDropdownHandler: (state, action) => {
      console.log("salesDropdownHandler");
      state.isSettings = state.isSettings === true ? false : true;
    },
    teamStaffDropDownHandler :(state, action) =>{
      state.isTeamsStaff = state.isTeamsStaff === true ? false : true;
    },
    sideNavHandler: (state, action) => {
      if (action.payload === false) {
        state.isOpenSideNav = action.payload;
      } else {
        state.isOpenSideNav = state.isOpenSideNav === false ? true : false;
      }
    },
    profieCardHandler: (state, action) => {
      if (action.payload === false) {
        state.isProfileCardOpen = false;
      } else {
        state.isProfileCardOpen =
          state.isProfileCardOpen === false ? true : false;
      }
    },
  },
});

export const { setingsDropdownHandler, sideNavHandler, profieCardHandler,teamStaffDropDownHandler } =
  handlerSlice.actions;

export default handlerSlice.reducer;
