import React from "react";
import { useSelector } from "react-redux";

const ProfileLogo = ({ className }) => {
  return (
    <div className={`h-7 w-7 rounded-full overflow-hidden  ${className} bg-red-100 `}>
      <img src="https://avatar.iran.liara.run/public/43" className={`h-full`} />
    </div>
  );
};
export default ProfileLogo;

export const UserName = () => {
  const userData = useSelector((state) => state?.userData);
  return (
    <p className="lg:text-base text-xs font-medium whitespace-pre text-ellipsis">
      Hello, {userData?.firstname} {" "}
      {userData?.lastname}
    </p>
  );
};
