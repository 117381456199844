import { BiHelpCircle } from "react-icons/bi";
import { GrLanguage } from "react-icons/gr";
import { IoIosLogOut } from "react-icons/io";
import { IoSettingsOutline } from "react-icons/io5";

export const cardMenus = [
    {
      icon: <IoSettingsOutline />,
      title: "Settings & Privacy",
      to: "/profile",
    },
    {
      icon: <BiHelpCircle />,
      title: "Help",
      to: "/",
    },
    // {
    //   icon: <GrLanguage />,
    //   title: "Languages",
    //   to: "/",
    // },
    {
      icon: <IoIosLogOut />,
      title: "Logout",
      onClick: true,
    },
  ];
  