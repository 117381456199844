import moment from "moment";
import { useEffect, useState } from "react";

export const useFormatDate = (date) => {
  const formattedDate = moment(date).format("MMMM Do YYYY, h:mm:ss a");
  return formattedDate;
};

export const useFormatOnlyDate = (date) => {
  const formattedDate = moment(date).format("MMMM Do YYYY");
  return formattedDate;
};




export const useFormatTime = (date) => {
  const formattedDate = moment(date).format("h:mm:ss a");
  return formattedDate;
};

export default [useFormatTime, useFormatDate,useFormatOnlyDate]