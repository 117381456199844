import React from 'react'

const CommingSoon = () => {
  return (
    <div className='w-full h-full flex justify-center items-center tracking-widest font-extralight text-3xl'>
      {/* <img src='https://img.freepik.com/free-vector/coming-soon-text-grunge-background_91128-1643.jpg?w=900&t=st=1722926033~exp=1722926633~hmac=6f28c2567e6dbf3dcf9b022d065f6d77ae93012a2dcd11568308bd4c077f1c36' className='h-96' /> */}
      {/* <img src='https://img.freepik.com/premium-vector/coming-soon-black-white-design-template_859243-262.jpg?w=740' className='h-96' /> */}
      <img src='https://img.freepik.com/free-vector/abstract-grunge-style-coming-soon-with-black-splatter_1017-26690.jpg?w=900&t=st=1722926243~exp=1722926843~hmac=29c3470b0367d5b2b5fed6d413ba0fcced8e80faf923b16ac8e4d57b441eefc2'  className='h-96'  />
    </div>
  )
}

export default CommingSoon