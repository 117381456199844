import React from "react";
import Layout from "../components/layout";
import { data } from "../components/dummydata";
import CommingSoon from "../components/commingSoon";

const Leads = () => {
  return (
    <Layout>
      <CommingSoon />
    </Layout>
  );
};

export default Leads;
