import { configureStore } from "@reduxjs/toolkit";
import handlerSlice from "../slices/handler";
import userSlice from "../slices/user";
import diagnosPatient from "../slices/diagnosPatient";
import permissionSlice from "../slices/permission";
import { consultantSlice } from "../slices/consultantSlice";



export default configureStore({
  reducer: {
    handler: handlerSlice,
    userData: userSlice,
    diagnosPatient: diagnosPatient,
    permissions : permissionSlice,
    // consultant: consultantSlice,
    // appointment: appointmentSlice,
  },
});
