import React from "react";

const Logo = ({className}) => {
  return (
    // <h1 className="text-md lg:text-xl font-semibold flex justify-start items-center gap-1 lg:gap-2">
      <img
        src="https://thehealthspanco.com/cdn/shop/files/Logo_280x100px.png?v=1706525837&width=280"
        className={`${className}`}
      />
    // </h1>
  );
};

export default Logo;
