// import React, { createContext, useContext, useEffect, useState } from 'react'
// import user from '../redux/slices/user'
// import Orders from '../pages/orders'
// import Appointment from '../pages/appointment'
// import axios from 'axios'
// import { useParams } from 'react-router-dom'


// const HealthSpanContext = createContext()
// export const useHealthSpanContext = () => useContext(HealthSpanContext)

// const HealthSpanProvider = ({ children }) => {

//   const { teamId } = useParams();
//   const [permission, setPermission] = useState({
//     role: 'admin',
//     dashboard: true,
//     diagnosis: true,
//     sessions: true,
//     leads: false,
//     orders: true,
//     consultant: true,
//     calendar: true,
//     appointment: false

//   })

//   const getPermissions = () => {
//     let config = {
//       method: 'get',
//       maxBodyLength: Infinity,
//       url: `https://api.thehealthspanco.com/api/v1/user/get/${teamId}`,
//       headers: {}
//     };

//     axios.request(config)
//       .then((response) => {
//         setPermission(response.data)
//         console.log("dashboard permission", response.data)
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   }

//   useEffect(() => {
//     getPermissions()
//   })

//   return (
//     <HealthSpanContext.Provider value={{ permission }}>{children}</HealthSpanContext.Provider>
//   )
// }

// export default HealthSpanProvider


// // ProfileProvider.js
// import React, { createContext, useContext, useState, useEffect } from "react";
// import { getCookie } from "./coockies";
// import { validateToken } from "./validateToken";

// const ProfileContext = createContext();

// const ProfileProvider = ({ children }) => {
//   const [profile, setProfile] = useState(null);
//   const token = getCookie("savage_token");

//   useEffect(() => {
//     const fetchProfile = async () => {
//       try {
//         if (token) {
//           const response = await validateToken(token);
//           console.log("response 17", response);
//           setProfile(response);
//         }
//       } catch (error) {
//         console.error("Error fetching profile:", error);
//       }
//     };

//     fetchProfile();
//   }, [token]);

//   return (
//     <ProfileContext.Provider value={{ profile }}>
//       {children}
//     </ProfileContext.Provider>
//   );
// };

// const useProfile = () => {
//   const context = useContext(ProfileContext);
//   if (!context) {
//     throw new Error("useProfile must be used within a ProfileProvider");
//   }
//   return context;
// };

// export { ProfileProvider, useProfile };


import React, { createContext, useContext, useState } from "react";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);

  const setUser = (user) => {
    setUserData(user);
  };

  return (
    <UserContext.Provider value={{ userData, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  return useContext(UserContext);
};